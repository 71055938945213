<template>
	<div>complex home page</div>
</template>

<script>
	export default {
		name: "index",
	};
</script>

<style scoped></style>
